@import "qt-design-system/scss/base/_variables";
@import "scss/base/variables";

.c-org {

    &__box {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0 1rem;

        &__item {
            flex: 50%;

            border: 1px solid $border-color;
            text-align: center;
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            padding: .5rem;
            margin-top: 1em;

            max-width: 8em;
            min-height: 5em;

            &__name {
                font-weight: bold;
                color: $dark-green;
            }

            &__name--manager {
                font-weight: bold;
            }

            &__child {
                cursor: pointer;
            }

        }

    }

    div &__container {
        margin: -5px -10px;
    }

    &__children {
        margin-top: 1em;
    }

}

.c-tooltip {

    cursor: pointer;

    &__loading {
        min-width: 10em;
        padding: 0.5em 0.5em;
    }

    &__bills {
        padding-top: .3em;

        div {
            display: flex;
            align-items: center;
            margin-top: -.5em;
            height: 3em;

            svg {
                font-size: 24px;
                padding-right: .2em;
            }
        }

        button {
            border: none;
            background-color: inherit;
            padding-bottom: .8em;
            cursor: pointer;
        }
    }

    
}

@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {

    .c-org {
    
        &__box__item {
            font-size: $mobile-md-font;
        }

    }
    
    .c-tooltip__bills {

        p {
            font-size: $mobile-md-font;
        }

        button {
            padding-top: 0.5em;
        }
    }

}