html {
	box-sizing: border-box;
	font-size: 8px;

	@include bp(xs) {
		font-size: 8.5px;
	}

	@include bp(sm) {
		font-size: 9px;
	}

	@include bp(md) {
		font-size: 9.5px;
		overflow-y: scroll;
	}

	@include bp(lg) {
		font-size: 10px;
	}
}

body {
	@include font-size(base);

	background-color: $body-bg;
	color: $text-color;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;


	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

td, th {
    padding: 0;
}