.b-file-upload {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.1em;
    border: 2px $light-grey dashed;
    border-radius: 12px;
    cursor: pointer;

    img {
        width: inherit;
        margin-right: 1em;
    }

    &__error {
        background-color: $bill-upload-error-bg;
        color: $bill-upload-error-color;
    }
    
    &__success {
        background-color: $bill-upload-success-bg;
        color: $bill-upload-success-color;
    }
}