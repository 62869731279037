@import "qt-design-system/scss/base/_variables";
@import "scss/base/variables";

.c-vendor-bills {
    display: flex;
    gap: 1em;

    div {
        min-width: 0;
        overflow-wrap: break-word;
    }

    &__organization__chart {
        width: 30%;
    }

    &__history {
        width: 100%;
    }
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {

    .c-vendor-bills {
        gap: 0;
        flex-wrap: wrap;

        // a:first-child {
        //     display: none !important;
        // }

        &__organization__chart {
            width: 100%;
        }
    }
}

/* Tablet ----------- */
@media only screen and (min-device-width : $tablet-min-breakpoint) and (max-device-width : $desktop-sm-max-breakpoint) {
    .c-vendor-bills {
        gap: 0;
        flex-wrap: wrap;

        &__organization__chart {
            width: 100%;
        }
    }
}