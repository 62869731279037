@import "qt-design-system/scss/base/_variables";
@import "scss/mixins/shadow";
@import "scss/base/variables";

.b-bill-table {
    display: flex;
    justify-content: space-between;

    line-height: 1.8em;
    border-bottom: 2px solid $border-color;

    &__header {
        font-weight: bold;
        
        &__posting {
            margin-top: -0.5em;
        }
    }

    &--expressapproval {
        color: $red;
        font-weight: bold;
    }
    
    div, a {
        padding: .8em .5em;
        flex: 1 1 0;
        width: 0;
        // border: 1px solid red;
    }

    div.b-bill-table__taxname {
        word-break: break-all;
    }

    &--double {
        flex-grow: 3 !important;
    }

    &--more-space {
        flex-grow: 1.5 !important;
    }

    &__type {
        @include shadow($box-shadow);
        display: flex;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;
        margin-top: 0.25em;
        font-size: 14px;
        font-weight: bold;
        padding: 0 !important;
        border-radius: 2px;

        background-color: $table_type_bgcolor;
        color: $table_type_fontcolor;

        width: $bill-action-type-width-md !important;
        height: $bill-action-type-height-md !important;

        &__vendorbill {
            background-color: $green;
            color: $white;
        }

    }

    &__duedate {
        font-weight: bold;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {

    .b-bill-table {
        font-size: $mobile-md-font;

        &__header {
            display: none;
        }

        div, a {
            padding: 0.5em 0.1em;
        }

        &__type {
            margin-top: 0.25em;
            width: $bill-action-type-width-sm !important;
            height: $bill-action-type-height-sm !important;
        }

        a.b-bill-table__id {
            display: none;
        }

        a.b-bill-table__vendorname {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 2.5 !important;
        }

        .b-bill-table__billtype {
            flex: 0.8;
            display: block !important;
        }

    }
    
}

// Tablet
@media only screen and (min-device-width : $tablet-min-breakpoint) and (max-device-width : $tablet-max-breakpoint) {

    .b-bill-table {

        &__type {
            margin-top: 0.4em;
            width: $bill-action-type-width-sm !important;
            height: $bill-action-type-height-sm !important;
        }

        .b-bill-table__billtype {
            flex: 0.5;
        }
    }
}