.c-chat-message {
    background: #fff;
    padding: 5px 10px;
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);

    &--secondary {
        background: rgb(235, 250, 236);
        background: -moz-linear-gradient(180deg, rgba(235, 250, 236, 1) 0%, rgba(247, 255, 248, 1) 24%, rgba(235, 250, 236, 1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(235, 250, 236, 1) 0%, rgba(247, 255, 248, 1) 24%, rgba(235, 250, 236, 1) 100%);
        background: linear-gradient(180deg, rgba(235, 250, 236, 1) 0%, rgba(247, 255, 248, 1) 24%, rgba(235, 250, 236, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebfaec", endColorstr="#ebfaec", GradientType=1);
    }

    &--tertiary {
        background: rgb(251, 250, 232);
        background: -moz-linear-gradient(180deg, rgba(251, 250, 232, 1) 0%, rgba(255, 254, 244, 1) 24%, rgba(251, 250, 232, 1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(251, 250, 232, 1) 0%, rgba(255, 254, 244, 1) 24%, rgba(251, 250, 232, 1) 100%);
        background: linear-gradient(180deg, rgba(251, 250, 232, 1) 0%, rgba(255, 254, 244, 1) 24%, rgba(251, 250, 232, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbfae8", endColorstr="#fbfae8", GradientType=1);
    }

    @include bp(md) {
        padding: 10px 20px;
    }

    .l-chatbox__item:nth-child(even) & {
        border-top-right-radius: 0;
        margin-left: 0px;
    }

    .l-chatbox__item:nth-child(odd) & {
        border-top-left-radius: 0;
        margin-right: 0px;
    }

    h6 {
        font-size: 14px;
        letter-spacing: 0.05em;
        color: rgba($black, 0.5);
        margin-bottom: 3px;
    }

    a {
        color: $brand-color;
        text-decoration: underline;
    }

    p {
        color: $black;
        font-size: 14px;
    }

    &__icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 15px;
        left: -30px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: inset 0 1px 1px #fff;

        .l-chatbox__item:nth-child(even) & {
            left: initial;
            right: -30px;
        }
    }

    &__text__heading {
        align-items: center;
    }
}