// Colors
$blue:#09102B;
// Qt custom style
$green:         #373f26;
$lemon:         #dbeb00;
$light-green:   #6e7600;
$dark-green:    #373f26;

$border-radius: 2px;

$body-bg: #F6F6F6;

// Headings
$headings-font-family: 'Titillium Web', sans-serif;
$brand-color-c:	$blue;

// Typography
$font-family-base: 'Titillium Web', sans-serif;
$font-weight-base: 200;

$line-height-base: 1.628571429;

// Font sizes
$font-size-base: md; // base size based on font-size labels in the sass map

// Margin
$margin-bottom-md: 1em;
$margin-top-md: 1em;

// Other
$corner-size: 10px;

// Bill history table
$box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);

$table_type_bgcolor: #eeeeee;
$table_type_fontcolor: #53586B;
$bill_status_width: 6em;

// Bill quick view: action
$bill-action-type-width-md: 60px;
$bill-action-type-height-md: 25px;

$bill-action-type-width-sm: 20px;
$bill-action-type-height-sm: 20px;

// Bill history approval flow
$circle-size: clamp(1.5rem, 5vw, 3rem);
$pipe-spacing: clamp(0.25rem, 2vw, 0.5rem);
$pending-color: $light-green;
$pipe-bg-color: #e0e0e0;
$pipe-height: 3px;

$bill-data-bg: #F8F8F8;

// Bill history badge
$bill-badge-width-md: 80px;
$bill-badge-height-md: 25px;

$bill-badge-width-sm: 60px;
$bill-badge-height-sm: 20px;

$badge-green-bg: $lemon;

// Bill data
$bill-header-fontsize: 26px;
$bill-header-status-bg: rgb(137, 137, 137);
$bill-data-spacing: 3.5em;

// File upload message colors
$bill-upload-success-bg: #e7f7e7;
$bill-upload-success-color: #8ecf99;
$bill-upload-error-bg: #f7e7e7;
$bill-upload-error-color: #cf8e99;

$mobile-md-font: 14px;
$mobile-sm-font: 12px;
$mobile-ssm-font: 10px;
$bill-data-spacing-sm: 1.5em;
$bill-data-spacing-md: 2.5em;
$bill-data-spacing-lg: 4em;

$tablet-md-font: 16px;
$desktop-sm-font: 14px;
$bill-detail-heading-font: 18px;

$bill-header-badge-padding: 0.2em 0.5em;

// App breakpoints
$mobile-min-breakpoint: 320px;
$mobile-max-breakpoint: 480px;
$tablet-min-breakpoint: 481px;
$tablet-max-breakpoint: 1024px;
$desktop-sm-min-breakpoint: 1025px;
$desktop-sm-max-breakpoint: 1300px;