.b-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    gap: 5em;
}

@media screen and (max-width: 600px) {
    .b-not-found {
        height: 100%;
        margin-top: 2em;
        flex-direction: column-reverse;
        gap: .5em;
    }

    .b-not-found-text {
        margin-left: 1.5em;
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .b-not-found {
        height: 100%;
        margin-top: 2em;
        flex-direction: column-reverse;
        gap: 1em;
    }

    .b-not-found-text {
        margin-left: 4em;
    }
}