.c-button--loading {

    div.c-bouncing-loader {
        margin: .5em 0;
    }

    .c-bouncing-loader > div {
        background-color: white;
        width: 8px;
        height: 8px;
    }

}