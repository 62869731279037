.c-alert {
    padding: 1.2em;
    position: relative;
    background: $green;
    color: #fff;
    
    svg {
        top: .2em;
        position: relative;
        margin-right: 0.2em;
    }

    button {
        position: absolute;
        background: transparent;
        color: #fff;
        top: 0.8em;
        right: 0.6em;
        border: 0;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
    }

    button::after {
        content: "✕";
    }

    button:hover {
        color: $almost-white;
    }

    &--success {
        clip-path: polygon(8px 0%, 100% 0%, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0% 100%, 0% 8px, 8px 0%);

        &::before {
            background: $green;
        }
    }

    &--danger {
        color: $red;

        &::before {
            background: $red;
        }
    }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .c-alert {
        font-size: $mobile-md-font;
        padding: 1em;


        button {
            font-size: $mobile-md-font;
        }

        svg {
            top: .1em;
            position: relative;
            margin-right: 0.2em;
        }

        div {
            width: 96%;
        }
    }
}