@import "qt-design-system/scss/base/_variables";
@import "scss/base/variables";

.b-bill-info__data__table {
    
    border: $border;
    background-color: $bill-data-bg;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    height: 100%;

    &__container {
        padding: 0.5em 1.5em;

        hr {
            margin: 0 !important;
            margin-bottom: 1em !important;
            background-color: $border;
        }
    }

    &__item {
        display: flex;
        padding-bottom: 1em;

        a.c-link {
            width: 20em !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &--left {
        flex: 1;
    }

    &--right {
        flex: 1;
    }

    &__heading {
        font-weight: bold;
        font-size: 18px;
    }
}

@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {

    .b-bill-info__data__table {
        margin-top: $bill-data-spacing-sm;

        &__heading {
            font-size: $mobile-md-font;
        }
        
        &__item {
            gap: 0.1em;
            padding-bottom: 0.5em;
        }


        &--left {
            flex: 0.7;
        }
    
        &--right {
            flex: 1;
        }
    }

}

@media only screen and (min-device-width : $tablet-min-breakpoint) and (max-device-width : $tablet-max-breakpoint) {

    .b-bill-info__data__table {

        &__heading {
            font-size: $tablet-md-font;
        }

        &__item {
            gap: $bill-data-spacing-sm;
            padding-bottom: 0.5em;
        }

        &--left {
            flex: 1;
        }
    
        &--right {
            flex: 1;
        }
    }

}