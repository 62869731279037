@import "qt-design-system/scss/base/_variables";
@import "scss/base/variables";

.l-chatbox__item {
    opacity: 1 !important;
}

.c-chat-message {
    margin-bottom: 1em;

    &__text {
        &__heading {
            display: flex;
            justify-content: space-between;
    
            &__name {
                font-weight: bold;
            }
        }
    
        &--status {
            font-weight: bold;

            &--approved {
                color: $green;
            }
        
            &--rejected {
                color: $red;
            }
        }
    }

    p {
        white-space: pre-line;
    }

    &__icon {
        background-image: url('../../../../../../public/assets/icon-user.svg');
    }
}

div.b-bill--flex {
    div.b-bill {
        margin-top: 2.5em;
    }

    div.l-chatbox {
        margin-top: 0;

        &__item {
            div.c-chat-message {
                margin-right: 0;
            }
        }
    }
}



@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {

    div.b-bill--flex div.b-bill {
        margin-top: $bill-data-spacing;
    }

}