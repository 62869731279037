@import "qt-design-system/scss/base/_variables";
@import "scss/base/variables";

.b-bill {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $bill-data-spacing-lg;

    &-info {
        display: flex;
        gap: 1.5em;
        flex: 1;

        margin-top: 1em;
    
        &__invoice {
            flex: 2;
    
            iframe {
                width: 100%;
                height: 100%;
            }
    
        }
    
        &__data {
            flex: 1.2;

            &__table {
                margin-top: 2.8em;
            }
        }

        &--flex {
            flex: 1;
            &:empty {
                flex: none;
            }
        }

        &__invoice__heading {
            display: flex;

            a {
                svg {
                    color: $green;
                }
            }
        }
    }

    &-header {
        margin-top: .5em;
        font-size: $bill-header-fontsize;
        display: flex;
        align-items: center;

        svg {
            font-size: $bill-header-fontsize + 8;
        }

        div,
        svg {
            margin-right: .3em;
        }

        &__reference {
            font-weight: bold;
        }

        &__recordType {
            border-radius: 2px;
            padding: $bill-header-badge-padding;
            background-color: $bill-header-status-bg;
            color: $white;
        }

        div.b-bill-header__recordType {
            font-size: $bill-detail-heading-font;
            text-transform: uppercase;
            margin-left: -0.3em;
        }

        &__status {
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 2px;
            padding: $bill-header-badge-padding;
            background-color: $bill-header-status-bg;
            color: $white;

            &--approved {
                background-color: $green;
            }

            &--rejected {
                background-color: $red;
            }
        }

        div.b-bill-header__status {
            font-size: $bill-detail-heading-font;
        }

    }

    &-posting {
        flex-basis: 100%;
    }

    &-action {
        flex-basis: 20%;
    }

    &-comment {
        .l-chatbox {
            margin-right: 0;
            margin-top: 5px;
        }
    }
}

.l-chatbox {
    width: 100%;
}


@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {

    .b-bill {
        margin-top: $bill-data-spacing-sm;

        &-header {
            font-size: $mobile-md-font;

            div.b-bill-header__status {
                font-size: $mobile-sm-font;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            div.b-bill-header__recordType {
                font-size: $mobile-sm-font;
            }

            div.b-bill-header__vendor {
                padding: 0 0.3em;
            }

            &__status {
                padding: .25em;
            }

            div,
            svg {
                margin-right: .5em;
            }

            svg {
                font-size: $mobile-sm-font + 14;
            }

            div.b-bill-header__reference {
                display: none;
            }
        }

        &-info {
            flex-wrap: wrap;
            gap: 0;

            &--flex {
                margin-top: 40px;
            }

            &__invoice {
                flex: none;
                width: 100%;

                iframe {
                    height: 300px;
                }

                &__heading {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5em;

                    a {
                        margin-left: 0.5em;
                        color: $green;

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        &-posting {
            flex-basis: 100%;
            margin-top: $bill-data-spacing-sm;
            overflow-x: auto;


            .b-bill-table {
                display: inline-flex;
                min-width: 100%;
                font-size: $mobile-md-font;

                div {
                    min-width: 5em;
                }

                div &--more-space {
                    min-width: 6em;
                }
            }
        }

        &-action {
            margin-top: $bill-data-spacing-lg;
            flex-basis: 100%;

            .c-form-select {
                font-size: $mobile-md-font !important;
            }

            .c-button {
                margin: 0 !important;
            }
        }

        &-comment {
            flex-basis: 100%;
            margin-top: $bill-data-spacing-sm;
            max-width: none;
        }
    }

}

// Tablet
@media only screen and (min-device-width : $tablet-min-breakpoint) and (max-device-width : $tablet-max-breakpoint) {
    .b-bill {
        margin-top: 0;

        &-header {
            font-size: $tablet-md-font;

            div.b-bill-header__status {
                font-size: $tablet-md-font;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__status {
                padding: .25em;
            }

            svg {
                font-size: $tablet-md-font + 14;
            }

            div.b-bill-header__recordType {
                font-size: inherit;
            }

        }

        &-info {
            gap: $bill-data-spacing-sm;

            &__invoice {
                flex: 3;
                width: 100%;
            }

            &__data {
                &__table {
                    margin-top: 2em;
                }
            }
        }

        &-posting {
            flex-basis: 100%;
            margin-top: $bill-data-spacing-md;
            overflow-x: auto;

            &--actionable {
                margin-bottom: $bill-data-spacing-sm;
            }

            .b-bill-table {
                display: inline-flex;
                min-width: 100%;

                div {
                    min-width: 5em;
                }

                div &--more-space {
                    min-width: 6em;
                }
            }
        }

        &-action {
            flex-basis: 20% !important;

            .c-button {
                margin: 0 !important;
            }
        }

        &-comment {
            flex-basis: 100%;
            margin-top: $bill-data-spacing-sm;
            max-width: none;
        }
    }
}