@import url('./fonts/TitilliumWeb@200300600/css/TitilliumWeb@200300600.css');

@import "qtds";

/**
 * Base
 */
@import "base/variables";
@import "base/base";

/**
 * Mixins
 */
 @import "mixins/section-padding";

/**
 * Blocks
 */
@import "blocks/b-bill-table";
@import "blocks/b-file-upload";

/**
 * Blocks
 */
@import "animation/fadeOut";
/**
 * Components
*/
@import "components/c-alert";
@import "components/c-chat-message";

@import "components/l-chatbox";

@mixin ocu-container {
    @include container;
    max-width: 1600px;
}

body {
    @include font-regular;
}

.body-container {
    @include ocu-container;
    @include spacing(margin, top, box);
    @include spacing(margin, bottom, itemspace);
}

body.qt-design-system {
    .b-header__container {
        @include ocu-container;
    }

    .h-wysiwyg-html {
        max-width: none;
    }

    .b-header {
        background-color: #F5F5F5;
    }

    .c-link {
        color: $light-green;
    }

    .c-button {
        background-color: $green;
    }
    
    .c-button:hover {
        background-color: $light-green;
    }

    .mb {
        margin-bottom: $margin-bottom-md;
    }

    .mt {
        margin-top: $margin-top-md;
    }

    .c-navigation-header li {
        padding: 0px 0.5em;
    }

    .c-link--no-chevron:hover {
        text-decoration: none !important;
    }

    .c-form-input-field {
        padding-top: .6em;
        padding-bottom: .6em;
        height: auto;
    }

    .c-form-select, .c-form-input-field {
        width: inherit;

        &:focus {
            border-color: $green;
        }
        &:focus-within {
            border-color: $green;
        }
        &:hover {
            border-color: $green;
        }
    }
    
    .c-form {

        fieldset {
            margin-bottom: 1em;
        }

        .c-button {
            margin-top: 1em;
        }

        &-label--required:after {
            content:" *";
            color: $red;
        }
    }

    .c-upload-instructions {
        margin-top: 0.5em;

        &__highlight{
            color: $red;
            font-weight: bold;
        }
    }

    .c-box--light {
        background: lighten($grey, 56);
        padding: 1.2em;

        display: flex;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;

            svg {
                height: 100%;
                margin-right: 0.5em;
            }
        }
    }

    .c-button--lighten {
        background: lighten($grey, 30);
    }

    li#organisation {
        display: none; 
    }

    .b-header__container {
        padding: 12px;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {
    
    body.qt-design-system {

        div, a {
            font-size: $mobile-md-font;
        }

        // Act on behalf notification
        .c-box--light {
            padding: 0.8em;
            font-size: $mobile-md-font;
        }

        .c-button--ligten {
            padding: 0.5em 0em;
        }

        li#organisation {
            display: block;
        }
    }
    
}
    