.l-chatbox {
    margin-top: 40px;
    @include bp(md){
        margin-left: 30px;
        margin-right: 30px;
    }

    &__item {
        margin: 10px 0;
        @include bp(md){
            opacity: 0;
        }

        @include bp(sm,max){
            margin: 10px 30px;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__typing {
        margin-top: 20px;

        letter-spacing: 0.1em;
        font-size: 12px;
        font-weight: 700;
        color: rgba(0,0,0,0.2);

        @include bp(sm,max){
            display: none;
        }

        span {

            @include animation( bubblebreath, 2s, both, linear, 0.6s);

            animation-iteration-count: infinite;

            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: rgba(0,0,0,0.1);
            margin: 0 3px;
            position: relative;
            top: -0.1em;

            &:first-child {
                background: $brand-color;
                @include animation( bubblebreath, 2s, both, linear, 0s);
            }

            &:last-child {
                @include animation( bubblebreath, 2s, both, linear, 1.2s);
            }
        }
    }

    &.animated {
        
        @include bp(md){
            .l-chatbox__typing {
                @include animation( writingFadeOut, 0.6s, forwards, $ease-out-expo, 3s);
            }
        }
        
        @include bp(md){
            .l-chatbox__item {
                

                &:nth-child(1) {
                    @include animation( chatIn, 0.6s, forwards, $ease-out-expo, 0s);
                }

                &:nth-child(2) {
                    @include animation( chatIn, 0.6s, forwards, $ease-out-expo, 1.2s);
                }

                &:nth-child(3) {
                    @include animation( chatIn, 0.6s, forwards, $ease-out-expo, 2.4s);
                }

                &:nth-child(4) {
                    @include animation( chatIn, 0.6s, forwards, $ease-out-expo, 3.6s);
                }
            }
        }
    }
}

/* @include animation( bubblebreath, 0.6s, forwards, $ease-out-expo, 0.3s); */

@include keyframes( bubblebreath ) {

    0% {
        background: rgba(255,255,255,0.5);
    }

    50% {
        background: #fff;
        transform: scale3d(1.4,1.4,1.4);
    }

    100% {
        background: rgba(255,255,255,0.5);
    }
}

/* @include animation( writingFadeOut, 0.6s, forwards, $ease-out-expo, 0.3s); */
@include keyframes( writingFadeOut ) {

    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}