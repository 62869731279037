/*
	----------------------------------------

	ANIMATIONS
	--
	Add animations here so you can reference them from other SCSS files
	Uses mixin/_animation.scss
	Uses mixin/_keyframes.scss

	Remember to add your newly made animation to _main.scss

	----------------------------------------

						anim		    animtime	fill		easing			delay
						--			    --			--			--				--
	@include animation(	fadeOut, 	    0.6s, 		backwards, 	$ease-out-expo, 0.3s);

    ----------------------------------------

	Examples:
    @include animation(	fadeOut, 0.6s, backwards, $ease-out-expo, 0.3s);
	@include animation( fadeOut );
    <div data-animate="animated fadeOut"></div>
    <div class="fadeOut"></div>

*/

.fadeOut {
	@include animation(fadeOut, 0.2s, $ease-out-expo, 0.2s);
	animation-fill-mode: forwards;
}

@include keyframes( fadeOut ) {
    0% {opacity: 1; transform: translate(0px, 0px);} 
    100% {opacity: 0; transform: translate(-20px, 0px);}
}