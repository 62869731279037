@import "scss/base/variables";

a.c-button__nextbill {
    padding-right: 8px !important;
    padding-bottom: 2px !important;
    margin-left: 1em;

    svg {
        font-size: 20px;
    }
}

@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $mobile-max-breakpoint) {
    a.c-button__nextbill {
        width: 50px;
        padding-left: 10px;
        svg {
            font-size: 16px;
            margin: 0;
        }
    }
}
