@import "qt-design-system/scss/base/_variables";
@import "scss/base/variables";

.c-stepper__container {
    flex-basis: 100%;
    overflow-x: auto;
}

.c-stepper {
    display: inline-flex;
    min-width:100%;
    width: 20em;
    padding: 0;
    margin-bottom: 0;
}

.c-stepper__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;

    // Circle
    &:before {
        --size: 3rem;
        content: "";
        color: $white;
        font-weight: bold;
        display: block;
        width: $circle-size;
        height: $circle-size;
        border-radius: 50%;
        background-color: $green;
        opacity: 0.8;
        margin: 0 auto 1rem;

    }

    // Pipe line
    &:not(:last-child) {
        &:after {
            content: "";
            position: relative;
            top: calc($circle-size / 2);
            width: calc(100% - $circle-size - calc($pipe-spacing * 2));
            left: calc(50% + calc($circle-size / 2 + $pipe-spacing));
            height: $pipe-height;
            background-color: $pipe-bg-color;
            order: -1;
        }
    }

    &:last-child {
        &:after {
            content: "";
            height: $pipe-height;
            order: -1;
        }
    }
}

.c-stepper__item--approved {
    &:before {
        content: "\2714";
    }
}

.c-stepper__item--pending {
    &:before {
        content: "\2025";
        background-color: $pending-color;
    }
}

.c-stepper__item--rejected {
    &:before {
        content: "\00D7";
        background-color: $red;
    }
}

.c-stepper__title {
    display: flex;
    flex: 1;
    justify-content: center;
    font-weight: bold;
    padding: 0.2em 1em;
}

.c-stepper__desc {
    padding-left: $pipe-spacing;
    padding-right: $pipe-spacing;
}

@media only screen and (min-device-width : $mobile-min-breakpoint) and (max-device-width : $tablet-max-breakpoint) {

    .c-stepper {
        margin: 0;

        &__desc {
            font-size: $mobile-md-font !important; 
        }
    }

}